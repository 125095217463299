import { Layout, Typography } from 'antd'
import './App.css'
import { UserProvider } from './contexts/userContext'

import { Content, Footer } from 'antd/es/layout/layout'

import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CallbackPage from './components/page/CallbackPage/CallbackPage'
import LogOutPage from './components/page/LogOutPage/LogOutPage'
import MyPointPage from './components/page/MyPointPage/MyPointPage'
import RewardsDetailPage from './components/page/RewardsDetailPage/RewardsDetailPage'
import RewardsListPage from './components/page/RewardsListPage/RewardsListPage'
import RewardsOrderedPage from './components/page/RewardsOrderedPage/RewardsOrderedPage'
import SignInCompletePage from './components/page/SignInCompletePage/SignInCompletePage'

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <Layout style={{ minHeight: '100vh' }}>
          {/* <Header
            style={{
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          ></Header> */}
          <Layout>
            <Content
              style={{
                textAlign: 'center',
                padding: '24px',
                backgroundColor: 'white',
              }}
            >
              <Routes>
                <Route path="/callback" element={<CallbackPage />} />
                <Route path="/logout" element={<LogOutPage />} />
                <Route path="/sign-complete" element={<SignInCompletePage />} />
                <Route path="/rewards" element={<RewardsListPage />} />
                <Route path="/rewards/:id" element={<RewardsDetailPage />} />
                <Route
                  path="/rewards/ordered"
                  element={<RewardsOrderedPage />}
                />
                <Route path="/points" element={<MyPointPage />} />
              </Routes>
            </Content>
          </Layout>
          <Footer
            style={{
              padding: '12px',
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <Typography.Text>Copyright 2024</Typography.Text>
          </Footer>
        </Layout>
      </UserProvider>
    </BrowserRouter>
  )
}

export default App
