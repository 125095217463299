import { StopFilled } from '@ant-design/icons'
import { Col, Row, Spin, Typography } from 'antd'
import { deleteField, doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'
import { UserCollectionName } from '../../../Config'
import { getUser } from '../../../contexts/userContext'
import { firestore, functions } from '../../../firebase'
import AmbiLogo from '../../ui/logos/AmbiLogo/AmbiLogo'
import OuraLogo from '../../ui/logos/OuraLogo/OuraLogo'
const { Title } = Typography

import './LogOutPage.css'

const LogOutPage = () => {
  const { userProfile, isUserProviderEffectComplete } = getUser()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!userProfile.lineId) return

    const updateRichmenuFunction = async () => {
      const updateRichMenu = httpsCallable(functions, 'changeRichMenu')
      const imageType = 'disconnect'

      try {
        await updateRichMenu({
          lineId: userProfile.lineId,
          imageType: imageType,
        })

        if (userProfile.lineId) {
          const userRef = doc(firestore, UserCollectionName, userProfile.lineId)
          await setDoc(
            userRef,
            {
              ouraToken: deleteField(),
            },
            { merge: true }
          )
        }
      } catch (err) {
        alert(err)
      }
      setLoading(false)
    }
    updateRichmenuFunction()
  }, [isUserProviderEffectComplete])

  function renderStatus() {
    if (loading) {
      return (
        <div className="container">
          <Row align={'middle'} justify={'space-between'}>
            <Col className="right-align">
              <AmbiLogo />
            </Col>
            <Col className="center-align">
              <Spin />
            </Col>
            <Col className="left-align">
              <OuraLogo />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p>連携解除中...</p>
            </Col>
          </Row>
        </div>
      )
    }
    return (
      <div className="container">
        <Row align={'middle'} justify={'space-between'}>
          <Col className="right-align">
            <AmbiLogo />
          </Col>
          <Col className="center-align">
            <StopFilled className="stop-icon" />
          </Col>
          <Col className="left-align">
            <OuraLogo />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>連携を解除しました!</Title>
            <p>画面を閉じてください。</p>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Row>
      <Col span={24}>{renderStatus()}</Col>
    </Row>
  )
}

export default LogOutPage
