import liff from '@line/liff'
import { doc, getDoc } from 'firebase/firestore'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { GetliffIdMap, UserCollectionName } from '../Config'
import { UserProfile, ouraTokenData } from '../components/model/UserProfile'
import { firestore } from '../firebase'

import { Configuration } from '../generated/api'

interface UserContextType {
  userProfile: UserProfile
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile>>
  isUserProviderEffectComplete: boolean
  apiConfig: Configuration
  contextStatus: string
}

const UserContext = createContext<UserContextType>({} as UserContextType)

export const getUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

interface UserProviderProps {
  children: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const location = useLocation()

  const [userProfile, setUserProfile] = useState<UserProfile>({
    lineId: '',
    displayName: '',
    email: '',
    ouraToken: {
      AccessToken: '',
      Expiry: null,
      RefreshToken: '',
      TokenType: 'Bearer',
    },
    lineInstance: null,
    lineIdToken: '',
    lineChannelAccessToken: '',
  })

  const [isUserProviderEffectComplete, setUserProviderEffectComplete] =
    useState(false)

  const [contextStatus, setContextStatus] = useState('')
  const [apiConfig, setApiConfig] = useState<Configuration>(new Configuration())

  useEffect(() => {
    const getUserProfile = async (
      lineId: string,
      displayName: string,
      email: string
    ) => {
      const userRef = doc(firestore, UserCollectionName, lineId)
      setContextStatus('fetching snapshot')
      const userSnap = await getDoc(userRef)
      try {
        if (userSnap.exists()) {
          setContextStatus('snapshot fetched')
          const data = userSnap.data() as UserProfile
          let ouraTokenData: ouraTokenData

          if (data.ouraToken) {
            ouraTokenData = {
              AccessToken: data.ouraToken.AccessToken,
              RefreshToken: data.ouraToken.RefreshToken,
              TokenType: data.ouraToken.TokenType,
              Expiry: data.ouraToken.Expiry,
            }
          } else {
            ouraTokenData = {
              AccessToken: '',
              Expiry: null,
              RefreshToken: '',
              TokenType: 'Bearer',
            }
          }

          userProfile.ouraToken.AccessToken = ouraTokenData.AccessToken

          setUserProfile((prevProfile) => ({
            ...prevProfile,
            lineId: lineId,
            displayName: displayName,
            email: email,
            ouraToken: ouraTokenData,
          }))
        } else {
          setContextStatus('snapshot not found')
          setUserProfile((prevProfile) => ({
            ...prevProfile,
            lineId: lineId,
            displayName: displayName,
            email: email,
          }))
        }
      } catch (err) {
        setContextStatus(`fetching snapshot error: ${err}`)
      }
      return userProfile
    }

    const initializeLiff = async () => {
      try {
        const liffIdMap = GetliffIdMap()
        const liffId = liffIdMap[location.pathname]

        await liff.init({ liffId: liffId })

        setUserProfile((prevProfile) => ({
          ...prevProfile,
          lineInstance: liff,
        }))
        setContextStatus('liff initialized')

        if (!liff.isLoggedIn()) {
          liff.login()
          return
        }

        const profile = await liff.getProfile()
        const lineId: string = profile.userId
        const displayName: string = profile.displayName

        const lineIdToken = liff.getDecodedIDToken()
        let email: string = ''
        if (lineIdToken && lineIdToken.email) {
          email = lineIdToken.email
        }

        const updatedUserProfile = await getUserProfile(
          lineId,
          displayName,
          email
        )

        // ここでgetUserProfileの結果が格納された状態で判断を行います
        if (
          !['callback', 'logout', 'sign-complete'].some((path) =>
            location.pathname.includes(path)
          )
        ) {
          if (!updatedUserProfile.ouraToken.AccessToken) {
            alert('Ouraアカウントと連携してください。')
            liff.closeWindow()
          }
        }

        const idToken = liff.getIDToken() || ''
        const channelAccessToken = liff.getAccessToken() || ''

        setApiConfig(
          new Configuration({
            basePath: process.env.REACT_APP_API_HOST,
            baseOptions: {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            },
          })
        )

        setUserProfile((prevProfile) => ({
          ...prevProfile,
          lineIdToken: idToken,
          lineChannelAccessToken: channelAccessToken,
        }))

        setUserProviderEffectComplete(true)
      } catch (err) {
        alert(`Context Error ${err}`)
      }
    }

    initializeLiff()
  }, [])
  return (
    <UserContext.Provider
      value={{
        userProfile,
        setUserProfile,
        isUserProviderEffectComplete,
        apiConfig,
        contextStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
