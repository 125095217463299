/* tslint:disable */
/* eslint-disable */
/**
 * 1000+1 Rewards API Server
 * Server for handling products, orders, and rewards for the 1000+1 application
 *
 * The version of the OpenAPI document: 1.0
 * Contact: develop@ambi.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface EchoHTTPError
 */
export interface EchoHTTPError {
    /**
     * 
     * @type {object}
     * @memberof EchoHTTPError
     */
    'message'?: object;
}
/**
 * type of the product
 * @export
 * @enum {string}
 */

export const EnumsProductType = {
    AMAZON_GIFT_CARD: 'AMAZON_GIFT_CARD',
    NORMAL: 'NORMAL'
} as const;

export type EnumsProductType = typeof EnumsProductType[keyof typeof EnumsProductType];


/**
 * 
 * @export
 * @interface RequestsPlaceOrderRequest
 */
export interface RequestsPlaceOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof RequestsPlaceOrderRequest
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ResponsesAdminOrders
 */
export interface ResponsesAdminOrders {
    /**
     * 
     * @type {Array<ResponsesOrderAdmin>}
     * @memberof ResponsesAdminOrders
     */
    'orders'?: Array<ResponsesOrderAdmin>;
}
/**
 * 
 * @export
 * @interface ResponsesAmazonReward
 */
export interface ResponsesAmazonReward {
    /**
     * 
     * @type {number}
     * @memberof ResponsesAmazonReward
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAmazonReward
     */
    'cardStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAmazonReward
     */
    'claimCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAmazonReward
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAmazonReward
     */
    'expirationDate'?: string;
}
/**
 * 
 * @export
 * @interface ResponsesOrder
 */
export interface ResponsesOrder {
    /**
     * 
     * @type {number}
     * @memberof ResponsesOrder
     */
    'orderId'?: number;
    /**
     * 
     * @type {ResponsesProduct}
     * @memberof ResponsesOrder
     */
    'product'?: ResponsesProduct;
    /**
     * 
     * @type {ResponsesReward}
     * @memberof ResponsesOrder
     */
    'reward'?: ResponsesReward;
}
/**
 * 
 * @export
 * @interface ResponsesOrderAdmin
 */
export interface ResponsesOrderAdmin {
    /**
     * 
     * @type {number}
     * @memberof ResponsesOrderAdmin
     */
    'orderId'?: number;
    /**
     * 
     * @type {ResponsesProduct}
     * @memberof ResponsesOrderAdmin
     */
    'product'?: ResponsesProduct;
    /**
     * 
     * @type {ResponsesReward}
     * @memberof ResponsesOrderAdmin
     */
    'reward'?: ResponsesReward;
    /**
     * 
     * @type {string}
     * @memberof ResponsesOrderAdmin
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface ResponsesOrderPlacedResponse
 */
export interface ResponsesOrderPlacedResponse {
    /**
     * 
     * @type {number}
     * @memberof ResponsesOrderPlacedResponse
     */
    'currentPointTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsesOrderPlacedResponse
     */
    'orderId'?: number;
    /**
     * 
     * @type {ResponsesProduct}
     * @memberof ResponsesOrderPlacedResponse
     */
    'product'?: ResponsesProduct;
    /**
     * 
     * @type {ResponsesReward}
     * @memberof ResponsesOrderPlacedResponse
     */
    'reward'?: ResponsesReward;
}
/**
 * 
 * @export
 * @interface ResponsesOrders
 */
export interface ResponsesOrders {
    /**
     * 
     * @type {Array<ResponsesOrder>}
     * @memberof ResponsesOrders
     */
    'orders'?: Array<ResponsesOrder>;
}
/**
 * 
 * @export
 * @interface ResponsesProduct
 */
export interface ResponsesProduct {
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'companyLogoURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesProduct
     */
    'monetaryAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesProduct
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesProduct
     */
    'pointCost'?: number;
    /**
     * 
     * @type {EnumsProductType}
     * @memberof ResponsesProduct
     */
    'productType'?: EnumsProductType;
}


/**
 * 
 * @export
 * @interface ResponsesProducts
 */
export interface ResponsesProducts {
    /**
     * 
     * @type {Array<ResponsesProduct>}
     * @memberof ResponsesProducts
     */
    'products'?: Array<ResponsesProduct>;
}
/**
 * 
 * @export
 * @interface ResponsesReward
 */
export interface ResponsesReward {
    /**
     * 
     * @type {ResponsesAmazonReward}
     * @memberof ResponsesReward
     */
    'amazonReward'?: ResponsesAmazonReward;
}
/**
 * 
 * @export
 * @interface ServicesAmazonResponseContentFunds
 */
export interface ServicesAmazonResponseContentFunds {
    /**
     * 
     * @type {ServicesAmazonResponseContentFundsAvailableFunds}
     * @memberof ServicesAmazonResponseContentFunds
     */
    'availableFunds'?: ServicesAmazonResponseContentFundsAvailableFunds;
    /**
     * 
     * @type {string}
     * @memberof ServicesAmazonResponseContentFunds
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesAmazonResponseContentFunds
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ServicesAmazonResponseContentFundsAvailableFunds
 */
export interface ServicesAmazonResponseContentFundsAvailableFunds {
    /**
     * 
     * @type {string}
     * @memberof ServicesAmazonResponseContentFundsAvailableFunds
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesAmazonResponseContentFundsAvailableFunds
     */
    'currencyCode'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Text String returned from base URL path.
         * @summary /rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Displays the funds available in Ambi\'s Amazon Incentives account.  Only available to admin users.
         * @summary /rewards/get-available-amazon-funds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGetAvailableAmazonFundsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/get-available-amazon-funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Text String returned from base URL path.
         * @summary /rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Displays the funds available in Ambi\'s Amazon Incentives account.  Only available to admin users.
         * @summary /rewards/get-available-amazon-funds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsGetAvailableAmazonFundsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicesAmazonResponseContentFunds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsGetAvailableAmazonFundsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthcheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Text String returned from base URL path.
         * @summary /rewards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGet(options?: any): AxiosPromise<string> {
            return localVarFp.rewardsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Displays the funds available in Ambi\'s Amazon Incentives account.  Only available to admin users.
         * @summary /rewards/get-available-amazon-funds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsGetAvailableAmazonFundsGet(options?: any): AxiosPromise<ServicesAmazonResponseContentFunds> {
            return localVarFp.rewardsGetAvailableAmazonFundsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /rewards/health or /healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsHealthGet(options?: any): AxiosPromise<void> {
            return localVarFp.rewardsHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Currently a healthcheck JSON at health route returning a 200 code and no body.
     * @summary /rewards/health or /healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public healthcheckGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).healthcheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Text String returned from base URL path.
     * @summary /rewards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public rewardsGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).rewardsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Displays the funds available in Ambi\'s Amazon Incentives account.  Only available to admin users.
     * @summary /rewards/get-available-amazon-funds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public rewardsGetAvailableAmazonFundsGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).rewardsGetAvailableAmazonFundsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Currently a healthcheck JSON at health route returning a 200 code and no body.
     * @summary /rewards/health or /healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public rewardsHealthGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).rewardsHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrdersApi - axios parameter creator
 * @export
 */
export const AdminOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all orders from the database.
         * @summary /rewards/orders/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/orders/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrdersApi - functional programming interface
 * @export
 */
export const AdminOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all orders from the database.
         * @summary /rewards/orders/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsOrdersAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAdminOrders>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsOrdersAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrdersApi - factory interface
 * @export
 */
export const AdminOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrdersApiFp(configuration)
    return {
        /**
         * Retrieves all orders from the database.
         * @summary /rewards/orders/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersAllGet(options?: any): AxiosPromise<ResponsesAdminOrders> {
            return localVarFp.rewardsOrdersAllGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrdersApi - object-oriented interface
 * @export
 * @class AdminOrdersApi
 * @extends {BaseAPI}
 */
export class AdminOrdersApi extends BaseAPI {
    /**
     * Retrieves all orders from the database.
     * @summary /rewards/orders/all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrdersApi
     */
    public rewardsOrdersAllGet(options?: AxiosRequestConfig) {
        return AdminOrdersApiFp(this.configuration).rewardsOrdersAllGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppOrdersApi - axios parameter creator
 * @export
 */
export const AppOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all orders for the logged in user from the database.
         * @summary /rewards/orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific order for the logged in user from the database.
         * @summary /rewards/orders/{oid}
         * @param {string} oid Order ID of the order to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersOidGet: async (oid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('rewardsOrdersOidGet', 'oid', oid)
            const localVarPath = `/rewards/orders/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Places an order for a product
         * @summary /rewards/orders
         * @param {RequestsPlaceOrderRequest} productID Product ID of the product to be redeemed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersPost: async (productID: RequestsPlaceOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productID' is not null or undefined
            assertParamExists('rewardsOrdersPost', 'productID', productID)
            const localVarPath = `/rewards/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppOrdersApi - functional programming interface
 * @export
 */
export const AppOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all orders for the logged in user from the database.
         * @summary /rewards/orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsOrdersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponsesOrders>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsOrdersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a specific order for the logged in user from the database.
         * @summary /rewards/orders/{oid}
         * @param {string} oid Order ID of the order to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsOrdersOidGet(oid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsOrdersOidGet(oid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Places an order for a product
         * @summary /rewards/orders
         * @param {RequestsPlaceOrderRequest} productID Product ID of the product to be redeemed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsOrdersPost(productID: RequestsPlaceOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesOrderPlacedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsOrdersPost(productID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppOrdersApi - factory interface
 * @export
 */
export const AppOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppOrdersApiFp(configuration)
    return {
        /**
         * Retrieves all orders for the logged in user from the database.
         * @summary /rewards/orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersGet(options?: any): AxiosPromise<Array<ResponsesOrders>> {
            return localVarFp.rewardsOrdersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific order for the logged in user from the database.
         * @summary /rewards/orders/{oid}
         * @param {string} oid Order ID of the order to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersOidGet(oid: string, options?: any): AxiosPromise<ResponsesOrder> {
            return localVarFp.rewardsOrdersOidGet(oid, options).then((request) => request(axios, basePath));
        },
        /**
         * Places an order for a product
         * @summary /rewards/orders
         * @param {RequestsPlaceOrderRequest} productID Product ID of the product to be redeemed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsOrdersPost(productID: RequestsPlaceOrderRequest, options?: any): AxiosPromise<ResponsesOrderPlacedResponse> {
            return localVarFp.rewardsOrdersPost(productID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppOrdersApi - object-oriented interface
 * @export
 * @class AppOrdersApi
 * @extends {BaseAPI}
 */
export class AppOrdersApi extends BaseAPI {
    /**
     * Retrieves all orders for the logged in user from the database.
     * @summary /rewards/orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppOrdersApi
     */
    public rewardsOrdersGet(options?: AxiosRequestConfig) {
        return AppOrdersApiFp(this.configuration).rewardsOrdersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a specific order for the logged in user from the database.
     * @summary /rewards/orders/{oid}
     * @param {string} oid Order ID of the order to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppOrdersApi
     */
    public rewardsOrdersOidGet(oid: string, options?: AxiosRequestConfig) {
        return AppOrdersApiFp(this.configuration).rewardsOrdersOidGet(oid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Places an order for a product
     * @summary /rewards/orders
     * @param {RequestsPlaceOrderRequest} productID Product ID of the product to be redeemed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppOrdersApi
     */
    public rewardsOrdersPost(productID: RequestsPlaceOrderRequest, options?: AxiosRequestConfig) {
        return AppOrdersApiFp(this.configuration).rewardsOrdersPost(productID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppProductsApi - axios parameter creator
 * @export
 */
export const AppProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all products the user is able to redeem.
         * @summary /rewards/products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsProductsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rewards/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a product by its ID.
         * @summary /rewards/products/{pid}
         * @param {number} pid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsProductsPidGet: async (pid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pid' is not null or undefined
            assertParamExists('rewardsProductsPidGet', 'pid', pid)
            const localVarPath = `/rewards/products/{pid}`
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppProductsApi - functional programming interface
 * @export
 */
export const AppProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all products the user is able to redeem.
         * @summary /rewards/products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsProductsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesProducts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsProductsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a product by its ID.
         * @summary /rewards/products/{pid}
         * @param {number} pid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rewardsProductsPidGet(pid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rewardsProductsPidGet(pid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppProductsApi - factory interface
 * @export
 */
export const AppProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppProductsApiFp(configuration)
    return {
        /**
         * Retrieves all products the user is able to redeem.
         * @summary /rewards/products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsProductsGet(options?: any): AxiosPromise<ResponsesProducts> {
            return localVarFp.rewardsProductsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a product by its ID.
         * @summary /rewards/products/{pid}
         * @param {number} pid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rewardsProductsPidGet(pid: number, options?: any): AxiosPromise<ResponsesProduct> {
            return localVarFp.rewardsProductsPidGet(pid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppProductsApi - object-oriented interface
 * @export
 * @class AppProductsApi
 * @extends {BaseAPI}
 */
export class AppProductsApi extends BaseAPI {
    /**
     * Retrieves all products the user is able to redeem.
     * @summary /rewards/products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppProductsApi
     */
    public rewardsProductsGet(options?: AxiosRequestConfig) {
        return AppProductsApiFp(this.configuration).rewardsProductsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a product by its ID.
     * @summary /rewards/products/{pid}
     * @param {number} pid Product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppProductsApi
     */
    public rewardsProductsPidGet(pid: number, options?: AxiosRequestConfig) {
        return AppProductsApiFp(this.configuration).rewardsProductsPidGet(pid, options).then((request) => request(this.axios, this.basePath));
    }
}


