import { CheckCircleFilled } from '@ant-design/icons'
import { Col, Result, Row, Typography } from 'antd'
import { doc, setDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useEffect } from 'react'
import { UserCollectionName } from '../../../Config'
import { getUser } from '../../../contexts/userContext'
import { firestore, functions } from '../../../firebase'
import AmbiLogo from '../../ui/logos/AmbiLogo/AmbiLogo'
import OuraLogo from '../../ui/logos/OuraLogo/OuraLogo'

import './SignInCompletePage.css'
const { Title } = Typography

const SignInCompletePage = () => {
  const { userProfile, isUserProviderEffectComplete } = getUser()

  useEffect(() => {
    if (!isUserProviderEffectComplete) return
    if (userProfile.lineId && userProfile.ouraToken.AccessToken) {
      const userRef = doc(firestore, UserCollectionName, userProfile.lineId)
      try {
        setDoc(
          userRef,
          {
            ouraToken: {
              AccessToken: userProfile.ouraToken.AccessToken,
              RefreshToken: userProfile.ouraToken.RefreshToken,
              TokenType: 'Bearer',
              Expiry: userProfile.ouraToken.Expiry,
            },
            lineDisplayName: userProfile.displayName,
            lineEmail: userProfile.email,
          },
          { merge: true }
        ).then(() => {
          const updateRichMenu = httpsCallable(functions, 'changeRichMenu')
          const imageType = 'connect'

          updateRichMenu({ lineId: userProfile.lineId, imageType: imageType })
            .then((result) => {
              console.log('Rich menu updated: ', result)
            })
            .catch((error) => {
              console.error(error)
            })
        })
      } catch (err) {
        alert(err)
      }
    }
  }, [isUserProviderEffectComplete])

  function renderStatus() {
    if (userProfile.lineId && userProfile.ouraToken.AccessToken) {
      return (
        <div className="container">
          <Row align={'middle'} justify={'space-between'}>
            <Col className="right-align">
              <AmbiLogo />
            </Col>
            <Col className="center-align">
              <CheckCircleFilled className="check-icon" />
            </Col>
            <Col className="left-align">
              <OuraLogo />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={3}>連携が完了しました!</Title>
              <p>画面を閉じてください。</p>
            </Col>
          </Row>
        </div>
      )
    }
    return (
      <div className="container">
        <Result
          status="error"
          title="問題が発生しました。"
          subTitle="時間を置いて再度お試しください。"
        />
      </div>
    )
  }

  return (
    <Row>
      <Col span={24}>{renderStatus()}</Col>
    </Row>
  )
}

export default SignInCompletePage
