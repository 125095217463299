/* tslint:disable */
/* eslint-disable */
/**
 * 1000+1 Points API Server
 * Server for handling point tracking for the 1000+1 application
 *
 * The version of the OpenAPI document: 1.0
 * Contact: develop@ambi.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface EchoHTTPError
 */
export interface EchoHTTPError {
    /**
     * 
     * @type {object}
     * @memberof EchoHTTPError
     */
    'message'?: object;
}
/**
 * 
 * @export
 * @interface RequestsAdminPointHistory
 */
export interface RequestsAdminPointHistory {
    /**
     * 
     * @type {string}
     * @memberof RequestsAdminPointHistory
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestsAdminPointHistory
     */
    'orderID'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestsAdminPointHistory
     */
    'pointChange'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestsAdminPointHistory
     */
    'titleID'?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestsAdminPointHistory
     */
    'userID'?: string;
}
/**
 * 
 * @export
 * @interface RequestsPointHistory
 */
export interface RequestsPointHistory {
    /**
     * 
     * @type {string}
     * @memberof RequestsPointHistory
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestsPointHistory
     */
    'orderID'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestsPointHistory
     */
    'pointChange'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestsPointHistory
     */
    'titleID'?: number;
}
/**
 * 
 * @export
 * @interface ResponsesAdminPointHistory
 */
export interface ResponsesAdminPointHistory {
    /**
     * 
     * @type {string}
     * @memberof ResponsesAdminPointHistory
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesAdminPointHistory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAdminPointHistory
     */
    'imageURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesAdminPointHistory
     */
    'pointChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAdminPointHistory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsesAdminPointHistory
     */
    'userID'?: string;
}
/**
 * 
 * @export
 * @interface ResponsesAdminPointHistoryResponse
 */
export interface ResponsesAdminPointHistoryResponse {
    /**
     * 
     * @type {Array<ResponsesAdminPointHistory>}
     * @memberof ResponsesAdminPointHistoryResponse
     */
    'pointHistories'?: Array<ResponsesAdminPointHistory>;
}
/**
 * 
 * @export
 * @interface ResponsesPagedPointHistories
 */
export interface ResponsesPagedPointHistories {
    /**
     * 
     * @type {number}
     * @memberof ResponsesPagedPointHistories
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsesPagedPointHistories
     */
    'page'?: number;
    /**
     * 
     * @type {Array<ResponsesPointHistory>}
     * @memberof ResponsesPagedPointHistories
     */
    'pointHistories'?: Array<ResponsesPointHistory>;
    /**
     * 
     * @type {number}
     * @memberof ResponsesPagedPointHistories
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsesPagedPointHistories
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ResponsesPointHistory
 */
export interface ResponsesPointHistory {
    /**
     * 
     * @type {string}
     * @memberof ResponsesPointHistory
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesPointHistory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesPointHistory
     */
    'imageURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsesPointHistory
     */
    'pointChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsesPointHistory
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ResponsesPointTotal
 */
export interface ResponsesPointTotal {
    /**
     * 
     * @type {number}
     * @memberof ResponsesPointTotal
     */
    'totalPoints'?: number;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Text String returned from base URL path.
         * @summary /points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Text String returned from base URL path.
         * @summary /points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckGet(options?: any): AxiosPromise<void> {
            return localVarFp.healthcheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Text String returned from base URL path.
         * @summary /points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet(options?: any): AxiosPromise<string> {
            return localVarFp.pointsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Currently a healthcheck JSON at health route returning a 200 code and no body.
         * @summary /healthcheck and /points/health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHealthGet(options?: any): AxiosPromise<void> {
            return localVarFp.pointsHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Currently a healthcheck JSON at health route returning a 200 code and no body.
     * @summary /healthcheck and /points/health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public healthcheckGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).healthcheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Text String returned from base URL path.
     * @summary /points
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public pointsGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).pointsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Currently a healthcheck JSON at health route returning a 200 code and no body.
     * @summary /healthcheck and /points/health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public pointsHealthGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).pointsHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPointHistoriesApi - axios parameter creator
 * @export
 */
export const AdminPointHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all point histories from the database.
         * @summary /points/history/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/history/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPointHistoriesApi - functional programming interface
 * @export
 */
export const AdminPointHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPointHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all point histories from the database.
         * @summary /points/history/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsHistoryAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesAdminPointHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsHistoryAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPointHistoriesApi - factory interface
 * @export
 */
export const AdminPointHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPointHistoriesApiFp(configuration)
    return {
        /**
         * Retrieves all point histories from the database.
         * @summary /points/history/all
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryAllGet(options?: any): AxiosPromise<ResponsesAdminPointHistoryResponse> {
            return localVarFp.pointsHistoryAllGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPointHistoriesApi - object-oriented interface
 * @export
 * @class AdminPointHistoriesApi
 * @extends {BaseAPI}
 */
export class AdminPointHistoriesApi extends BaseAPI {
    /**
     * Retrieves all point histories from the database.
     * @summary /points/history/all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPointHistoriesApi
     */
    public pointsHistoryAllGet(options?: AxiosRequestConfig) {
        return AdminPointHistoriesApiFp(this.configuration).pointsHistoryAllGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppPointHistoriesApi - axios parameter creator
 * @export
 */
export const AppPointHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all point histories for the logged in user from the database.
         * @summary /points/history
         * @param {string} [sort] Sorted Field name
         * @param {boolean} [sortDesc] Sort Descending instead of Ascending?
         * @param {number} [page] Starting Page for results
         * @param {number} [limit] Number of results per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryGet: async (sort?: string, sortDesc?: boolean, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new entry in the logged in user\'s point history
         * @summary /points
         * @param {RequestsPointHistory} pointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost: async (pointHistory: RequestsPointHistory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointHistory' is not null or undefined
            assertParamExists('pointsPost', 'pointHistory', pointHistory)
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointHistory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves total number of points for the logged in user.
         * @summary /points/total
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsTotalGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppPointHistoriesApi - functional programming interface
 * @export
 */
export const AppPointHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppPointHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all point histories for the logged in user from the database.
         * @summary /points/history
         * @param {string} [sort] Sorted Field name
         * @param {boolean} [sortDesc] Sort Descending instead of Ascending?
         * @param {number} [page] Starting Page for results
         * @param {number} [limit] Number of results per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsHistoryGet(sort?: string, sortDesc?: boolean, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesPagedPointHistories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsHistoryGet(sort, sortDesc, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new entry in the logged in user\'s point history
         * @summary /points
         * @param {RequestsPointHistory} pointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsPost(pointHistory: RequestsPointHistory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsPost(pointHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves total number of points for the logged in user.
         * @summary /points/total
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsTotalGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesPointTotal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsTotalGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppPointHistoriesApi - factory interface
 * @export
 */
export const AppPointHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppPointHistoriesApiFp(configuration)
    return {
        /**
         * Retrieves all point histories for the logged in user from the database.
         * @summary /points/history
         * @param {string} [sort] Sorted Field name
         * @param {boolean} [sortDesc] Sort Descending instead of Ascending?
         * @param {number} [page] Starting Page for results
         * @param {number} [limit] Number of results per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryGet(sort?: string, sortDesc?: boolean, page?: number, limit?: number, options?: any): AxiosPromise<ResponsesPagedPointHistories> {
            return localVarFp.pointsHistoryGet(sort, sortDesc, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new entry in the logged in user\'s point history
         * @summary /points
         * @param {RequestsPointHistory} pointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost(pointHistory: RequestsPointHistory, options?: any): AxiosPromise<void> {
            return localVarFp.pointsPost(pointHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves total number of points for the logged in user.
         * @summary /points/total
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsTotalGet(options?: any): AxiosPromise<ResponsesPointTotal> {
            return localVarFp.pointsTotalGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppPointHistoriesApi - object-oriented interface
 * @export
 * @class AppPointHistoriesApi
 * @extends {BaseAPI}
 */
export class AppPointHistoriesApi extends BaseAPI {
    /**
     * Retrieves all point histories for the logged in user from the database.
     * @summary /points/history
     * @param {string} [sort] Sorted Field name
     * @param {boolean} [sortDesc] Sort Descending instead of Ascending?
     * @param {number} [page] Starting Page for results
     * @param {number} [limit] Number of results per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPointHistoriesApi
     */
    public pointsHistoryGet(sort?: string, sortDesc?: boolean, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return AppPointHistoriesApiFp(this.configuration).pointsHistoryGet(sort, sortDesc, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new entry in the logged in user\'s point history
     * @summary /points
     * @param {RequestsPointHistory} pointHistory Points to be added or removed from history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPointHistoriesApi
     */
    public pointsPost(pointHistory: RequestsPointHistory, options?: AxiosRequestConfig) {
        return AppPointHistoriesApiFp(this.configuration).pointsPost(pointHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves total number of points for the logged in user.
     * @summary /points/total
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPointHistoriesApi
     */
    public pointsTotalGet(options?: AxiosRequestConfig) {
        return AppPointHistoriesApiFp(this.configuration).pointsTotalGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalPointHistoriesApi - axios parameter creator
 * @export
 */
export const InternalPointHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new entry in the specified user\'s point history
         * @summary /points/internal
         * @param {RequestsAdminPointHistory} adminPointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsInternalPost: async (adminPointHistory: RequestsAdminPointHistory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminPointHistory' is not null or undefined
            assertParamExists('pointsInternalPost', 'adminPointHistory', adminPointHistory)
            const localVarPath = `/points/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPointHistory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves total number of points for the specified user.
         * @summary /points/total/internal
         * @param {string} userID UserID of the user to retrieve point total for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsTotalInternalGet: async (userID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('pointsTotalInternalGet', 'userID', userID)
            const localVarPath = `/points/total/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalPointHistoriesApi - functional programming interface
 * @export
 */
export const InternalPointHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalPointHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new entry in the specified user\'s point history
         * @summary /points/internal
         * @param {RequestsAdminPointHistory} adminPointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsInternalPost(adminPointHistory: RequestsAdminPointHistory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsInternalPost(adminPointHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves total number of points for the specified user.
         * @summary /points/total/internal
         * @param {string} userID UserID of the user to retrieve point total for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsTotalInternalGet(userID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsesPointTotal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsTotalInternalGet(userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalPointHistoriesApi - factory interface
 * @export
 */
export const InternalPointHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalPointHistoriesApiFp(configuration)
    return {
        /**
         * Creates a new entry in the specified user\'s point history
         * @summary /points/internal
         * @param {RequestsAdminPointHistory} adminPointHistory Points to be added or removed from history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsInternalPost(adminPointHistory: RequestsAdminPointHistory, options?: any): AxiosPromise<void> {
            return localVarFp.pointsInternalPost(adminPointHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves total number of points for the specified user.
         * @summary /points/total/internal
         * @param {string} userID UserID of the user to retrieve point total for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsTotalInternalGet(userID: string, options?: any): AxiosPromise<ResponsesPointTotal> {
            return localVarFp.pointsTotalInternalGet(userID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalPointHistoriesApi - object-oriented interface
 * @export
 * @class InternalPointHistoriesApi
 * @extends {BaseAPI}
 */
export class InternalPointHistoriesApi extends BaseAPI {
    /**
     * Creates a new entry in the specified user\'s point history
     * @summary /points/internal
     * @param {RequestsAdminPointHistory} adminPointHistory Points to be added or removed from history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalPointHistoriesApi
     */
    public pointsInternalPost(adminPointHistory: RequestsAdminPointHistory, options?: AxiosRequestConfig) {
        return InternalPointHistoriesApiFp(this.configuration).pointsInternalPost(adminPointHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves total number of points for the specified user.
     * @summary /points/total/internal
     * @param {string} userID UserID of the user to retrieve point total for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalPointHistoriesApi
     */
    public pointsTotalInternalGet(userID: string, options?: AxiosRequestConfig) {
        return InternalPointHistoriesApiFp(this.configuration).pointsTotalInternalGet(userID, options).then((request) => request(this.axios, this.basePath));
    }
}


