import { Button, Spin } from 'antd'
import { httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getUser } from '../../../contexts/userContext'
import { functions } from '../../../firebase'
import './RewardsDetailPage.css'
import { useRewardsDetail } from './hooks/useRewardsDetail'

interface LinePaymentResponseData {
  returnCode: string
  returnMessage: string
  info: {
    transactionId: string
    transactionDate: string
  }
}

interface LineReferenceNoResponseData {
  returnCode: string
  returnMessage: string
  info: {
    referenceNo: string
  }
}

const RewardsDetailPage = () => {
  const { userProfile, apiConfig, isUserProviderEffectComplete } = getUser()
  const navigate = useNavigate()
  const [submitLoading, setSubmitLoading] = useState(false)

  const [isTermsChecked, setIsTermsChecked] = useState(false)

  const { id } = useParams()

  const { loading, product, points, isLinePay } = useRewardsDetail(
    id!,
    apiConfig,
    isUserProviderEffectComplete
  )

  const handleClick = async () => {
    if (!isTermsChecked) {
      alert('ポイントの使用には規約に同意する必要があります。')
      return
    }

    setSubmitLoading(true)

    try {
      if (product?.pointCost && points < product?.pointCost) {
        setSubmitLoading(false)
        alert('ポイントが足りません。')
        return
      }

      if (isLinePay) {
        const result = await deplositLinePayment()
        if (!result) {
          setSubmitLoading(false)
          alert('LINE Payの購入に失敗しました。')
          return
        }
      }

      // const orderData = await createOrder(apiConfig, Number(product?.id))

      // if (orderData?.orderId) {
      //   navigate('/rewards/ordered', {
      //     state: {
      //       transactionId: orderData.orderId,
      //       title: product?.title,
      //       description: product?.description,
      //       companyName: product?.companyName,
      //       image: product?.image,
      //       pointCost: product?.pointCost,
      //     },
      //   })
      // }

      setSubmitLoading(false)
    } catch (err) {
      setSubmitLoading(false)
      alert('購入に失敗しました。')
      console.error(JSON.stringify(err))
    }
  }

  const deplositLinePayment = async (): Promise<boolean> => {
    try {
      const linePayReferenceFunction = httpsCallable(
        functions,
        'linePayReference'
      )
      const result = await linePayReferenceFunction({
        channelAccessToken: userProfile.lineChannelAccessToken,
      })

      const reference_data = result.data as LineReferenceNoResponseData

      if (reference_data.returnCode === '0000') {
        const linePayDepositFunction = httpsCallable(
          functions,
          'linePayDeposit'
        )
        const result = await linePayDepositFunction({
          lineReferenceNo: reference_data.info.referenceNo,
          monetaryAmount: product?.monetaryAmount,
        })

        const data = result.data as LinePaymentResponseData
        if (data.returnCode === '0000') {
          navigate('/rewards/ordered', {
            state: {
              transactionId: data.info.transactionId,
              title: product?.title,
              description: product?.description,
              companyName: product?.companyName,
              image: product?.image,
              pointCost: product?.pointCost,
            },
          })
          return true
        } else {
          alert(data)
          return false
        }
      } else {
        return false
      }
    } catch (err) {
      alert(JSON.stringify(err))
      return false
    }
  }

  function renderButton() {
    if (!submitLoading) {
      return (
        <Button className="navigate-button" onClick={handleClick}>
          この商品に交換する
        </Button>
      )
    } else {
      return <Spin />
    }
  }

  const handleTermsChange = (e: any) => {
    // チェックボックスの値を更新します。
    setIsTermsChecked(e.target.checked)
  }

  function openExternalLink(url: string) {
    // LIFFのopenWindowメソッドを使用して、外部ブラウザでリンクを開きます
    userProfile.lineInstance.openWindow({
      url: url,
      ex7ternal: true,
    })
  }

  function renderView() {
    if (!loading) {
      return (
        <div className="container">
          <img className="reward-logo" src={product?.image} />
          <div className="reward-container">
            <p className="reward-title">{product?.title}</p>
            <p className="reward-points">{`${product?.pointCost}pt`}</p>
            <p className="reward-description">{product?.description}</p>
            <p className="reward-company">提供元: {product?.companyName}</p>

            <p className="reward-agreement">
              ポイントの使用にあたり、株式会社Ambiの提供する
              <a
                onClick={() =>
                  openExternalLink(
                    'https://mini-clove-fb4.notion.site/96dd19105e8244f89d9f6d8cb1d2af3b'
                  )
                }
              >
                利用規約
              </a>
              と
              <a
                onClick={() =>
                  openExternalLink(
                    'https://mini-clove-fb4.notion.site/1b672933edfa4e9aa3b402d961041f9d'
                  )
                }
              >
                プライバシーポリシー
              </a>
              に同意する。
            </p>

            {isLinePay && (
              <p className="reward-agreement">
                LINEPayナンバーをユーザー様に代わりLINE
                Pay株式会社から送金主催の株式会社Ambiへ提供することに同意する。
              </p>
            )}

            <input
              type="checkbox"
              checked={isTermsChecked}
              onChange={handleTermsChange}
            />
            <label>上記に同意する</label>
          </div>

          {renderButton()}
          <p>使用可能ポイント: {points}pt</p>
        </div>
      )
    } else {
      return (
        <div className="container">
          <Spin />
        </div>
      )
    }
  }

  return <div>{renderView()}</div>
}

export default RewardsDetailPage
