import { Button, Col, Divider, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../../contexts/userContext'
import { AppProductsApi, ResponsesProducts } from '../../../generated/api'
import {
  AppPointHistoriesApi,
  Configuration,
  ResponsesPointTotal,
} from '../../../generated/api/points'
import RewardCard from '../../ui/RewardCard/RewardCard'
import './RewardsListPage.css'

interface DataType {
  id: string
  title: string
  image: string
  pointCost: number
}

const RewardsListPage = () => {
  const navigate = useNavigate()
  const { userProfile, isUserProviderEffectComplete } = getUser()
  const [loading, setLoading] = useState(true)
  const [currentTotalPoint, setCurrentTotalPoint] = useState(0)
  const [products, setProducts] = useState<DataType[]>([])

  useEffect(() => {
    if (!isUserProviderEffectComplete) return
    const getTotalPointAndProducts = async () => {
      const apiConfig = new Configuration({
        basePath: process.env.REACT_APP_API_HOST,
        baseOptions: {
          headers: {
            Authorization: `Bearer ${userProfile.lineIdToken}`,
          },
        },
      })
      const getPoint = async () => {
        const api = new AppPointHistoriesApi(apiConfig)
        try {
          const response = await api.pointsTotalGet()
          const data = response.data as ResponsesPointTotal
          const totalPoints = data.totalPoints || 0
          setCurrentTotalPoint(totalPoints)
          console.log(currentTotalPoint)
        } catch (err) {
          console.error(JSON.stringify(err))
        }
      }

      const getProducts = async () => {
        const api = new AppProductsApi(apiConfig)

        try {
          const response = await api.rewardsProductsGet()
          const data = response.data as ResponsesProducts
          const data_products =
            data.products?.map((product) => ({
              id: String(product.id),
              title: product.name || '',
              image: product.companyLogoURL || '',
              pointCost: product.pointCost || 0,
            })) || []

          setProducts(data_products)
        } catch (err) {
          console.error(JSON.stringify(err))
        }
      }

      await getPoint()
      await getProducts()
      setLoading(false)
    }

    if (userProfile.lineIdToken) {
      getTotalPointAndProducts()
    }
  }, [isUserProviderEffectComplete])

  function renderRewards() {
    return products.map((card) => (
      <Col key={card.id} span={12}>
        <RewardCard
          id={card.id}
          title={card.title}
          image={card.image}
          pointCost={card.pointCost}
          // event={() => {
          //   navigate(`/rewards/${card.id}`)
          // }}
        />
      </Col>
    ))
  }

  function renderView() {
    if (!loading) {
      return (
        <div className="container">
          <p className="ambi-points">1000+1 Points</p>
          <p className="point">{currentTotalPoint}</p>
          <p className="maintenance">
            *只今メンテナンス期間のため、
            <br />
            商品交換機能を停止させて頂いております。
          </p>
          <p className="maintenance-reactivation">
            再開は8/2(金) 夜頃を予定しています。
          </p>
          <Button
            className="navigate-button"
            type="link"
            onClick={() => {
              navigate('/points')
            }}
          >
            ポイント履歴ページへ
          </Button>
          <p className="content-label">商品一覧</p>
          <Divider />
          <Row gutter={16}>{renderRewards()}</Row>
        </div>
      )
    } else {
      return (
        <div className="container">
          <Spin />
        </div>
      )
    }
  }

  return <div>{renderView()}</div>
}

export default RewardsListPage
