import { Col, Row, Spin, Typography } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser } from '../../../contexts/userContext';
import { functions } from '../../../firebase';
import AmbiLogo from '../../ui/logos/AmbiLogo/AmbiLogo';
import OuraLogo from '../../ui/logos/OuraLogo/OuraLogo';

import { OuraConfig } from '../../../Config';
import './CallbackPage.css';
const { Title } = Typography

interface ResponseData {
  access_token: string
  expires_in: number
  refresh_token: string
}

function convertTimestamp(timestamp: number): Timestamp {
  const dateInMillis = timestamp * 1000;
  return Timestamp.fromMillis(dateInMillis);
}

const CallbackPage = () => {
  const navigate = useNavigate()
  const { setUserProfile, userProfile, isUserProviderEffectComplete } =
    getUser()
  const [statusText, setStatusText] = useState('Oura連携中...')

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const code = query.get('code') || ''

  useEffect(() => {
    if (!isUserProviderEffectComplete) return
    const generateOuraAccessToken = async () => {
      try {
        if (!code) {
          setStatusText('Oura連携準備中...')
          const oura_base_url = OuraConfig.oura_base_url
          const oura_client_id = OuraConfig.oura_client_id
          const oura_redirect_uri = OuraConfig.oura_redirect_uri

          const oura_url = `${oura_base_url}client_id=${oura_client_id}&state=XXX&redirect_uri=${oura_redirect_uri}&response_type=code`
          userProfile.lineInstance.openWindow({
            url: oura_url,
            external: false,
          })
          return
        }

        if (userProfile.lineId) {
          const generateOuraAccessTokenFunction = httpsCallable(
            functions,
            'generateOuraAccessToken'
          )
          const result = await generateOuraAccessTokenFunction({ code: code })
          const data = result.data as ResponseData

          setUserProfile((prevProfile) => ({
            ...prevProfile,
            ouraToken: {
              AccessToken: data.access_token,
              RefreshToken: data.refresh_token,
              TokenType: 'Bearer',
              Expiry: convertTimestamp(
                Math.floor(Date.now() / 1000) + data.expires_in
              ),
            },
          }))
          navigate('/sign-complete')
        }
      } catch (err) {
        console.log(`Callback page error : ${JSON.stringify(err)}`)
      }
    }
    generateOuraAccessToken()
  }, [isUserProviderEffectComplete])

  function renderStatus() {
    return (
      <div className="container">
        <Row align={'middle'} justify={'space-between'}>
          <Col className="right-align">
            <AmbiLogo />
          </Col>
          <Col className="center-align">
            <Spin />
          </Col>
          <Col className="left-align">
            <OuraLogo />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={3}>{statusText}</Title>
          </Col>
        </Row>
      </div>
    )

    return null
  }

  return <div>{renderStatus()}</div>
}

export default CallbackPage
