const liffIdMapDev: Record<string, string> = {
  '/callback': '2000662404-Dy02gNaE',
  '/sign-complete': '2000662404-Dy02gNaE',
  '/points': '2000662404-gkMQD61x',
  '/rewards': '2000662404-Qjr7GXVl',
  '/logout': '2000662404-bxZxnGrN',
}

const liffIdMapStaging: Record<string, string> = {
  '/callback': '2000662440-83X0jeqp',
  '/sign-complete': '2000662440-83X0jeqp',
  '/points': '2000662440-3awk7oZB',
  '/rewards': '2000662440-qjKz42QM',
  '/logout': '2000662440-PZ7qnNK6',
}

const liffIdMapProduction: Record<string, string> = {
  '/callback': '2000781847-Z63yx8DM',
  '/sign-complete': '2000781847-Z63yx8DM',
  '/points': '2000781847-KEn5kmMx',
  '/rewards': '2000781847-EQZn5v0a',
  '/logout': '2000781847-nzZXvydY',
}

export const GetliffIdMap = () => {
  if (process.env.REACT_APP_ENV === 'develop') {
    return liffIdMapDev
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return liffIdMapStaging
  } else if (process.env.REACT_APP_ENV === 'production') {
    return liffIdMapProduction
  }
  return liffIdMapDev
}

export const OuraConfig: Record<string, string | undefined> = {
  oura_base_url: 'https://cloud.ouraring.com/oauth/authorize?',
  oura_client_id: process.env.REACT_APP_OURA_CLIENT_ID,
  oura_redirect_uri: process.env.REACT_APP_OURA_CALLBACK_URL,
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const UserCollectionName =
  process.env.REACT_APP_USER_COLLECTION_NAME || '1001users'
