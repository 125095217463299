import { Card } from 'antd'
import './RewardCard.css'
const { Meta } = Card
type RewardCardProps = {
  id: string
  title: string
  image: string
  pointCost: number
  event?: () => void
}

const RewardCard = (props: RewardCardProps) => {
  return (
    <div>
      <Card
        hoverable
        bordered
        className="reward-card"
        onClick={props.event}
        cover={<img alt={props.title} src={props.image} />}
      >
        <Meta title={props.title} description={`${props.pointCost}pt`} />
      </Card>
    </div>
  )
}

export default RewardCard
