import { Button, Col, Row, Spin, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../../contexts/userContext'
import './MyPointPage.css'

import {
  AppPointHistoriesApi,
  Configuration,
  ResponsesPagedPointHistories,
  ResponsesPointHistory,
  ResponsesPointTotal,
} from '../../../generated/api/points'

interface DataType {
  key: number | undefined
  date: string | undefined
  point: number | undefined
  type: string | undefined
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Point',
    dataIndex: 'point',
    key: 'point',
    render: (point: number) => {
      const color = point > 0 ? '#0596db' : '#1e2529'
      const prefix = point > 0 ? '+' : ''
      return (
        <div>
          <span style={{ color: color }}>{prefix}</span>
          {point}
        </div>
      )
    },
  },
  {
    title: 'type',
    key: 'type',
    dataIndex: 'type',
    render: (type: string) => {
      let color = '#0596db'

      //  https://github.com/Ambi-Sleep/1000plus1-Point-Server/blob/586953e454a5b270537ce60f074ee51efbeccd65/utils/pointTitles.go#L13
      if (type === '睡眠ポイント') {
        type = 'ポイント獲得'
      } else if (type === 'Admin Adjustment') {
        type = 'ポイント調整'
      } else if (type === 'Special Event') {
        type = '特典ポイント'
      } else {
        // type == 'Reward Redeemed'
        color = '#1e2529'
        type = 'ポイント交換'
      }
      return <span style={{ color: color }}>{type}</span>
    },
  },
]

const formatDate = (dateString: string) => {
  try {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}/${month}/${day}`
  } catch (err) {
    return ''
  }
}

const MyPointPage = () => {
  const navigate = useNavigate()
  const { userProfile, isUserProviderEffectComplete } = getUser()
  const [loading, setLoading] = useState(true)
  const [currentTotalPoint, setCurrentTotalPoint] = useState(0)

  const [tableData, setDataTable] = useState<DataType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    if (!isUserProviderEffectComplete) return

    const getTotalPointAndHistory = async () => {
      const apiConfig = new Configuration({
        basePath: process.env.REACT_APP_API_HOST,
        baseOptions: {
          headers: {
            Authorization: `Bearer ${userProfile.lineIdToken}`,
          },
        },
      })

      const api = new AppPointHistoriesApi(apiConfig)

      const getPoint = async () => {
        try {
          const response = await api.pointsTotalGet()
          const data = response.data as ResponsesPointTotal
          const totalPoints = data.totalPoints || 0
          setCurrentTotalPoint(totalPoints)
        } catch (err) {
          console.error(err)
        }
      }

      const getPointHistory = async () => {
        try {
          const params = {
            sort: 'created_at',
            sortDesc: true,
            page: currentPage,
            limit: 10,
          }
          const response = await api.pointsHistoryGet(
            params.sort,
            params.sortDesc,
            params.page,
            params.limit
          )
          if (!response.data) {
            return
          }

          // can not use swagger because of the typo createdAt => CreateAt
          const data: ResponsesPagedPointHistories = response.data

          const pointHistories =
            data.pointHistories?.map((pointHistory: ResponsesPointHistory) => ({
              key: pointHistory.id,
              date: formatDate(pointHistory.date || ''),
              point: pointHistory.pointChange,
              type: pointHistory.title,
            })) || []
          setDataTable(pointHistories)
          const totalRows = data.totalRows || 0
          setTotalPage(totalRows)
        } catch (err) {
          console.error(err)
        }
      }

      await getPoint()
      await getPointHistory()
      setLoading(false)
    }

    if (userProfile.lineIdToken) {
      getTotalPointAndHistory()
    }
  }, [isUserProviderEffectComplete, currentPage, userProfile.lineIdToken])

  function renderStatus() {
    if (!loading) {
      return (
        <div className="container">
          <p className="ambi-points">1000+1 Points</p>
          <p className="point">{currentTotalPoint}</p>
          <p className="point-limit">
            *獲得ポイントは取得日から180日で失効します。
          </p>
          <Button
            className="navigate-button"
            type="link"
            onClick={() => {
              navigate('/rewards')
            }}
          >
            商品交換ページへ飛ぶ
          </Button>
          <p className="content-label">ポイント履歴</p>
          <Table
            columns={columns}
            dataSource={tableData}
            showHeader={false}
            className="reward-table"
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: totalPage,
              onChange: (page) => {
                setCurrentPage(page)
              },
            }}
          />
        </div>
      )
    } else {
      return (
        <div className="container">
          <Spin />
        </div>
      )
    }
  }

  return (
    <Row>
      <Col span={24}>{renderStatus()}</Col>
    </Row>
  )
}

export default MyPointPage
