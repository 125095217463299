import { Divider } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getUser } from '../../../contexts/userContext'
import {
  AppPointHistoriesApi,
  Configuration,
  ResponsesPointTotal,
} from '../../../generated/api/points'

import './RewardsOrderedPage.css'

const RewardsOrderedPage = () => {
  const { userProfile, isUserProviderEffectComplete } = getUser()

  const [currentTotalPoint, setCurrentTotalPoint] = useState(0)

  const location = useLocation()
  const { transactionId, title, description, companyName, image, pointCost } =
    location.state || {
      transactionId: '',
      title: '',
      description: '',
      companyName: '',
      image: '',
      pointCost: '',
    }

  useEffect(() => {
    if (!isUserProviderEffectComplete) return
    const getPoint = async () => {
      const apiConfig = new Configuration({
        basePath: process.env.REACT_APP_API_HOST,
        baseOptions: {
          headers: {
            Authorization: `Bearer ${userProfile.lineIdToken}`,
          },
        },
      })

      const api = new AppPointHistoriesApi(apiConfig)

      try {
        const response = await api.pointsTotalGet()
        const data = response.data as ResponsesPointTotal
        const totalPoints = data.totalPoints || 0
        setCurrentTotalPoint(totalPoints)
        console.log(currentTotalPoint)
      } catch (err) {
        console.error(JSON.stringify(err))
      }
    }
    if (userProfile.lineIdToken) {
      getPoint()
    }
  }, [isUserProviderEffectComplete])

  function renderView() {
    return (
      <div className="container">
        <img className="reward-logo" src={image} />

        <h1 className="order-complete">交換が完了しました</h1>
        <p className="order-description">
          取引ID: {transactionId} <br />
        </p>
        <Divider />

        <div className="reward-container">
          <p className="reward-container-title">交換した商品</p>
          <p className="reward-title">{title}</p>
          <p className="reward-points">{pointCost}pt</p>
          <p className="reward-description">{description}</p>

          <p className="reward-company">提供元: {companyName}</p>
        </div>
      </div>
    )
  }

  return <div>{renderView()}</div>
}

export default RewardsOrderedPage
