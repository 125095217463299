import { useEffect, useState } from 'react'
import { Configuration } from '../../../../generated/api'
import { getPoints, getProduct } from '../../../../lib/api'
interface ProductDetails {
  id: string
  title: string
  description: string
  companyName: string
  image: string
  pointCost: number
  monetaryAmount: number
}

interface UseRewardsDetailReturn {
  loading: boolean
  product: ProductDetails | null
  points: number
  isLinePay: boolean
}

export const useRewardsDetail = (
  id: string,
  apiConfig: Configuration,
  isUserProviderEffectComplete: boolean
): UseRewardsDetailReturn => {
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState<ProductDetails | null>(null)
  const [points, setPoints] = useState(0)
  const [isLinePay, setIsLinePay] = useState(false)

  useEffect(() => {
    if (!isUserProviderEffectComplete) return

    const fetchData = async () => {
      setLoading(true)
      try {
        const pointData = await getPoints(apiConfig)
        setPoints(pointData?.totalPoints || 0)

        const productData = await getProduct(apiConfig, Number(id))

        if (productData !== null) {
          setProduct({
            id: String(productData.id),
            title: productData.name || '',
            description: productData.description || '',
            companyName: productData.companyName || '',
            image: productData.imageUrl || '',
            pointCost: productData.pointCost || 0,
            monetaryAmount: productData.monetaryAmount || 0,
          })

          if (productData.name?.includes('LINE Pay')) {
            setIsLinePay(true)
          }
        }
      } catch (err) {
        console.error(err)
      }
      setLoading(false)
    }

    fetchData()
  }, [id, apiConfig, isUserProviderEffectComplete])

  return { loading, product, points, isLinePay }
}
