import {
  AppOrdersApi,
  AppProductsApi,
  Configuration,
  ResponsesOrder,
  ResponsesProduct,
} from '../generated/api'

import {
  AppPointHistoriesApi,
  ResponsesPointTotal,
} from '../generated/api/points'

export const getPoints = async (
  apiConfig: Configuration
): Promise<ResponsesPointTotal | null> => {
  const api = new AppPointHistoriesApi(apiConfig)
  try {
    const response = await api.pointsTotalGet()
    const data = response.data as ResponsesPointTotal
    return data
  } catch (error) {
    console.error('Error fetching points', error)
    return null
  }
}

export const getProduct = async (
  apiConfig: Configuration,
  id: number
): Promise<ResponsesProduct | null> => {
  const api = new AppProductsApi(apiConfig)
  try {
    const response = await api.rewardsProductsPidGet(id)
    const data = response.data as ResponsesProduct
    return data
  } catch (error) {
    console.error('Error fetching product', error)
    return null
  }
}

export const createOrder = async (
  apiConfig: Configuration,
  productID: number
): Promise<ResponsesOrder | null> => {
  const api = new AppOrdersApi(apiConfig)
  try {
    const response = await api.rewardsOrdersPost({ productId: productID })
    const data = response.data as ResponsesOrder
    return data
  } catch (error) {
    console.error('Error creating order', error)
    return null
  }
}
